<script setup>

const props = defineProps({
  client: {
    type: Object,
    required: false,
  },
});

const navItems = (id) => {
  return [
    { text: "Details", icon: "mdi-file", to: `/clients/${id}` },
    {
      text: "Notes",
      icon: "mdi-note",
      to: `/clients/${id}/notes`,
    },
    {
      text: "Rates",
      icon: "mdi-cash",
      to: `/clients/${id}/rates`,
    },
    {
      text: "Notifications",
      icon: "mdi-bell",
      to: `/clients/${id}/notifications`,
    },
    { 
      text: "Users", 
      icon: "mdi-account-group",
      to: `/clients/${id}/users` 
    },
    {
      text: "Settings",
      icon: "mdi-cogs",
      to: `/clients/${id}/settings`,
    },
    {
      text: "Integrations",
      icon: "mdi-hammer-wrench",
      to: `/clients/${id}/integrations`,
    },
    {
      text: "Developer",
      icon: "mdi-xml",
      to: `/clients/${id}/developer`,
    },
  ];
};

</script>

<template>
  <div class="d-flex ml-4">
    <div class="w-25">
      <v-card class="mx-auto pa-2 w-100">
        <div class="text-center" v-if="!client">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.client">
          <v-list-subheader>CLIENT/ {{ client.name }}</v-list-subheader>

          <v-list-item
            exact
            v-if="client"
            v-for="(item, i) in navItems(client.id)"
            :key="i"
            :value="item"
            color="primary"
            :to="item.to"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div class="w-100">
      <slot />
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 768px) {
  .v-card .mx-auto {
    max-width: 75px;
  }
}
</style>
